var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',[_c('validation-provider',{attrs:{"name":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v("New Password")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.new_pass),callback:function ($$v) {_vm.new_pass=$$v},expression:"new_pass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"invalid-feedback",style:(_vm.feedbackStyle(_vm.$store.getters['app/currentBreakPoint']))},[_vm._v(_vm._s(validationContext.errors[0]))])])],1)]}}])})],1)],1),(_vm.new_pass)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.submitChanges}},[_vm._v(" "+_vm._s(_vm.$t('forms.save_changes'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary","type":"button","to":{ 
      name: 'students-view', 
      params: {
                  id: _vm.school.id,
                  student_id: _vm.userData.id,
                },
    },"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){_vm.hasHistory() 
      ? _vm.$router.go(-1) 
      : _vm.$router.push({ 
        name: 'students-view', 
        params: {
                    id: _vm.school.id,
                    student_id: _vm.userData.id,
                  },
      })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }